<template>
    <div class="include-header">
        <section v-if="route === 'Home'" id="slider-home" class="slider-element slider-parallax min-vh-60 min-vh-md-100">
            <div class="slider-inner" style="">
                <div class="vertical-middle slider-element-fade">
                    <div class="container py-5">
                        <div class="row pt-5">
                            <div class="col-lg-5 col-md-8">
                                <div class="slider-title">
                                    <div class="badge badge-pill badge-default" style="font-size: 20px">Development</div>
                                    <h2>mobile applications and web sites</h2>
                                    <h3 class="text-rotater mb-2" data-separator="," data-rotate="fadeIn" data-speed="3500">- From  <span>A to Z,</span> turnkey!</h3>
                                    <p></p>
                                    <a href="/brief" class="button button-rounded button-large nott ls0">Fill a Brief</a>
                                    <a href="/contact" class="button button-rounded button-large button-light text-dark bg-white border nott ls0">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="video-wrap h-100 d-block d-lg-none">
                    <div class="video-overlay" style="background: rgba(255,255,255,0.85);"></div>
                </div>

            </div>
        </section><!-- #slider end -->

        <section v-else-if="route === 'Apps'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Apps</div>
                <h1>What We Do</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Apps</li>
                </ol>
            </div>

        </section><!-- #page-title end -->

        <section v-else-if="route === 'Testing'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Testing</div>
                <h1>Testing of Your Application</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/">Services</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Testing</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'UI/UX'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">UI/UX</div>
                <h1>Our offers as for the consulting UX!</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/">Services</a></li>
                    <li class="breadcrumb-item active" aria-current="page">UI/UX</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'Mobility'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Mobility</div>
                <h1>Corporate Mobility Partner</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/">Services</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Mobility</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'Cloud'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Cloud</div>
                <h1>Development partner of the cloud environment</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/">Services</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Cloud</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'Web'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Web</div>
                <h1>Your Web Development Partner</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/">Services</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Web</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'Solutions'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Solutions</div>
                <h1>Partner for Corporate Mobility</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Solutions</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'Brief'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Brief</div>
                <h1>Fill the Brief</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Brief</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
        <section v-else-if="route === 'Contact'"  id="page-title" class="page-title-parallax page-title-center page-title-dark"  data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

            <div class="container clearfix mt-6">
                <div class="badge badge-pill border border-light text-light">Get In Touch</div>
                <h1>Contact us</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact</li>
                </ol>
            </div>

        </section><!-- #page-title end -->
    </div>
</template>

<script>
    export default {
        name: "Slider",
        props:['route']
    }
</script>
<link rel="preload" as="image" href="../assets/demos/seo/images/hero/hero-1.jpg" />
<style scoped>
    #slider-home .slider-inner {
         background: #FFF url('../assets/demos/seo/images/hero/hero-1.jpg') center center no-repeat;
         background-size: cover;
     }
    #page-title {
        background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('../assets/demos/seo/images/about-title.jpg');
        background-size: cover;
        padding: 120px 0;
    }
</style>